import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "next/link";
import Image from "next/legacy/image";
import Avatar from "@mui/material/Avatar";
import { red, indigo, blue, lightGreen, pink } from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloatingActionButtons from "../chats/FloatingActionButtonsBox";
// import FloatingActionButtons from "../chats/message/test";
import {
  faLine,
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import Divider from "@mui/material/Divider";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MarkUnreadChatAltRoundedIcon from "@mui/icons-material/MarkUnreadChatAltRounded";
import CameraEnhanceIcon from "@mui/icons-material/CameraEnhance";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { useRouter } from "next/router";
import Cookies from "universal-cookie";
import { Config, ImgUrl } from "../../config";
import * as ApiProduct from "../../api/product";
import { TwitterShareButton } from 'react-share';
import useResponsiveItems from "@/hooks/useResponsiveItems";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "0px solid",
  boxShadow: "unset",
  fontSize: 18,
}));

interface Item {
  category_id: string | number;
  category_title: string;
  category_title_en: string;
  category_icon_image: string;
  category_type: string;
}

interface FilteredItem {
  id: string | number;
  name: string;
  nameEn: string;
  image: string;
  type: string;
}

export default function Footer() {
  const [dataProductGroup, setProductGroup] = React.useState([]);

  const queryLimit = useResponsiveItems({ xsCount: 4, smCount: 4, mdCount: 6, lgCount: 10, xlCount: 12, xxlCount: 16 });

  const imagePayment = "/assets/images/logo/logopayment.png";
  const imagePayment2 = "/assets/images/logo/true.png";
  const imagePayment3 = "/assets/images/logo/pp.png";
  const imageLogistic1 = "/assets/images/logo/logistic.png";
  const imageLogistic2 = "/assets/images/logo/flash.png";
  const imageLogistic3 = "/assets/images/logo/THP.png";
  const imageLineOA = "/assets/images/logo/Line-OA-icon.png";
  // const imageLogistic4 = '/assets/images/logo/THP.png';
  let imgLogoMaaboom = "/assets/images/logo/logomaboom.png";
  let imgLogoGooglePlay = "https://cdn.maaboom.com/web/maaboom-google-play.webp";
  let imgLogoIos = "https://cdn.maaboom.com/web/maaboom-ios.webp";

  const router = useRouter();
  const { pathname } = router;
  const getUrl = pathname.split("/")[1] as string;
  const cookies = new Cookies();
  const authToken = cookies.get("token");
  const getToken = authToken !== "" ? authToken : null;
  const [firstLoad, setFirstLoad] = React.useState(true);

  React.useEffect(() => { setFirstLoad(false); }, []);

  const handleClick = () => {
    if (isAndroid || isWindows) {
      window.location.href =
      "https://play.google.com/store/apps/details?id=com.petsploy360innovative.petsploy&hl=th";
        // "/download-application";
    } else if (isIos || isMac) {
      window.location.href =
        // "https://apps.apple.com/th/app/maaboom/id1662787944?l=th";
        "https://apps.apple.com/th/app/maaboom/id1662787944?l=th"
    } else {
      window.location.href =
        // "https://play.google.com/store/apps/details?id=com.petsploy360innovative.petsploy&hl=th";
        "https://play.google.com/store/apps/details?id=com.petsploy360innovative.petsploy"
    }
  };

  const handleClickDownload = () => {
    window.location.href =
      "/download-application"
  }

  React.useEffect(() => {
    setFirstLoad(false);
  }, []);

  const [isAndroid, setIsAndroid] = React.useState(false);
  const [isIos, setIsIos] = React.useState(false);
  const [isMac, setIsMac] = React.useState(false);
  const [isWindows, setIsWindows] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const ua = window.navigator.userAgent;
      setIsAndroid(/android/i.test(ua));
      setIsIos(/iPad|iPhone|iPod/.test(ua) && !window.MSStream);
      setIsMac(/Mac/i.test(ua));
      setIsWindows(/Win/i.test(ua));
    }
  }, []);

  React.useEffect(() => {
    let active = true;

    const fetchData = async () => {
      if (active) {
      }
      try {
        // Fetch data for Order Address All
        const resProductGroup = await ApiProduct.ProductGroup();
        // console.log(resProductGroup.data);
        setProductGroup(resProductGroup?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    return () => {
      active = false;
    };
  }, [getUrl]);

  // console.log(dataProductGroup);

  const filteredAndMappedProduct: FilteredItem[] = dataProductGroup?.filter((item: Item) => item.category_type === "Product")
    ?.map((item: Item, idx: number) => {
      return {
        id: item.category_id,
        name: item.category_title,
        nameEn: item.category_title_en,
        image: item.category_icon_image,
        type: item.category_type,
      };
    });

  // console.log(filteredAndMappedProduct)
  const filteredAndMappedService: FilteredItem[] = dataProductGroup?.filter((item: Item) => item.category_type === "Service")
    ?.map((item: Item, idx: number) => {
      return {
        id: item.category_id,
        name: item.category_title,
        nameEn: item.category_title_en,
        image: item.category_icon_image,
        type: item.category_type,
      };
    });

  return (
    <>
      {!(getUrl === "account" || getUrl === "signin" || getUrl === "printLabel") && (
        <footer>
          <Box>
            <FloatingActionButtons />
            <Box className="border-footer"></Box>
            <Container 
              maxWidth="lg"
              sx={{ 
                pt: 3, height: "50%",
                // maxWidth: {
                //   xs: '100%', // 0-600px
                //   // sm: '800px', // 600-960px
                //   md: '1360px', // 960-1280px
                //   lg: '1920px', // 1280-1920px
                //   xl: '2560px' // 1920px and up
                // },
                // margin: '0 auto',
                // paddingLeft: {
                //   xs: '20px', // 0-600px
                //   sm: '20px', // 600-960px
                //   md: '50px', // 960-1280px
                //   lg: '50px', // 1280-1920px
                //   xl: '80px'  // 1920px and up
                // },
                // paddingRight: {
                //   xs: '20px', // 0-600px
                //   sm: '20px', // 600-960px
                //   md: '50px', // 960-1280px
                //   lg: '50px', // 1280-1920px
                //   xl: '80px'  // 1920px and up
                // } 
              }}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid container
                    xs={12}
                    md={12}
                    lg={12}
                    // spacing={4}
                    // sx={{ px: '30px' }}
                  >
                    <Grid xs={6} sm={4} md={3} lg={3} sx={{ padding: { xs: 0, md: 2 }, paddingBottom: { md: 0 } }}>
                      <Item className="menu-list">
                        <Box
                          component="h3"
                          id="category-b"
                          sx={{
                            mt: 0,
                            color: "#000000",
                            fontSize: {
                              xs: "16px",  // Font size for extra small screens
                              sm: "16px",  // Font size for small screens
                              md: "18px",  // Font size for medium screens (default desktop sizes)
                              lg: "18px",  // Font size for large screens
                              xl: "18px"   // Font size for extra large screens
                            },
                            lineHeight: "16px" // Set line height
                          }}
                        >
                          ศูนย์ดูแลลูกค้า
                        </Box>
                        <Box component="ul" aria-labelledby="category-a">
                          {/* <li>
                            <Link
                              href="/portal/ทำไมต้องเป็นพันธมิตรกับ-petsploy"
                              target="_blank"
                            >
                              ทำไมต้องเป็นพันธมิตรกับ MaaBoom
                            </Link>
                          </li> */}

                          {/* <li>
                            <Link
                              href="/portal/สิทธิประโยชน์-ที่พันธมิตรจะได้รับ"
                              target="_blank"
                            >
                              สิทธิประโยชน์พันธมิตร
                            </Link>
                          </li> */}
                          <li>
                            <Link
                              href="/portal/ขั้นตอน-เปิดร้าน-เป็นพันธมิตรทางธุรกิจกับเรา"
                              target="_blank"
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                              ขั้นตอน “เปิดร้าน”
                              </Typography>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href={`${Config.apiSellerEndpoint}account/signin`}
                              target="_blank"
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                              เริ่มขายสินค้า/บริการ
                              </Typography>
                            </Link>
                          </li>
                          {/* <li>
                            <Link
                              href="/portal/กิจกรรม-ส่งเสริมการตลาด"
                              target="_blank"
                            >
                              กิจกรรม “ส่งเสริมการตลาด”
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link
                              href={`${Config.apiSellerEndpoint}account/signin`}
                              target="_blank"
                            >
                              Seller Centre (เข้าสู่ระบบร้านค้า)
                            </Link>
                          </li> */}
                          {/* <li>
                            <Link
                              href="https://help.maaboom.com/"
                              target="_blank"
                            >
                              Help Centre
                            </Link>
                          </li> */}
                          <li>
                            {/* petsPloy */}
                            <Link
                              href={`/contact`}
                              target="_blank"
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                              ติดต่อเรา
                              </Typography>
                            </Link>
                          </li>
                          <li>
                            <Link
                              href="/community/verify-place"
                              target="_blank"
                            >
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                              ลงทะเบียนธุรกิจ
                              </Typography>
                            </Link>
                          </li>
                        </Box>
                      </Item>
                    </Grid>
                    <Grid xs={6} sm={4} md={3} lg={3} sx={{ padding: { xs: 0, md: 2 }, paddingBottom: { md: 0 } }}>
                      <Item className="menu-list">
                        <Box
                          component="h3"
                          id="category-b"
                          sx={{
                            mt: 0,
                            color: "#000000",
                            fontSize: {
                              xs: "16px", 
                              sm: "16px", 
                              md: "18px", 
                              lg: "18px", 
                              xl: "18px"  
                            },
                            lineHeight: "16px" 
                          }}
                        >
                          หมวดหมู่สินค้าและบริการ
                        </Box>
                        <Box
                          component="ul"
                          aria-labelledby="category-b"
                          sx={{ mb: 0 }}
                        >
                          {filteredAndMappedProduct?.map((item:any, idx:any) => (
                            <li key={idx}>
                              <Link
                                href={{
                                  pathname: `/search/allsearch`,
                                  query: {
                                    ...router.query,
                                    category_id: item.id,
                                    keyword: router.query.keyword || '',
                                    product_type: "Product" 
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: '14px', 
                                      sm: '14px', 
                                      md: '14px', 
                                      lg: '14px', 
                                      xl: '14px'
                                    },
                                    display: 'block', // Makes the entire area clickable
                                    padding: '5px 0px 0px 0px',
                                    lineHeight: '1.5',
                                  }}
                                >
                                {item.name || "หมวดหมู่ไม่มีชื่อ"}
                                </Typography>
                              </Link>
                            </li>
                          ))}
                         
                        </Box>
                        <Box
                          component="ul"
                          aria-labelledby="category-b"
                          sx={{ my: 0 }}
                        >
                          {filteredAndMappedService?.map((item:any, idx:any) => (
                            <li key={idx}>
                              <Link
                                href={{
                                  pathname: `/search/allsearch`,
                                  query: {
                                    ...router.query,
                                  
                                    category_id: item.id, 
                                    keyword: router.query.keyword || '',
                                    product_type: "Service" 
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: {
                                      xs: '14px', 
                                      sm: '14px', 
                                      md: '14px', 
                                      lg: '14px', 
                                      xl: '14px'
                                    },
                                    display: 'block', // Makes the entire area clickable
                                    padding: '5px 0px 0px 0px',
                                    lineHeight: '1.5',
                                  }}
                                >
                                {item.name}
                                </Typography>
                              </Link>
                            </li>
                          ))}
                         
                        </Box>
                      </Item>

                    </Grid>
                    <Grid xs={6} sm={4} md={3} lg={3} sx={{ padding: { xs: 0, md: 2 }, paddingBottom: { md: 0 } }}>
                      <Item className="menu-list">
                        <Box
                          component="h3"
                          id="category-b"
                          sx={{
                            mt: 0,
                            color: "#000000",
                            fontSize: {
                              xs: "16px",  // Font size for extra small screens
                              sm: "16px",  // Font size for small screens
                              md: "18px",  // Font size for medium screens (default desktop sizes)
                              lg: "18px",  // Font size for large screens
                              xl: "18px"   // Font size for extra large screens
                            },
                            lineHeight: "16px" // Set line height
                          }}
                        >
                          แหล่งความรู้และคูปอง
                        </Box>

                        <Box
                          component="ul"
                          aria-labelledby="category-b"
                          sx={{ my: 0, listStyleType: 'none', padding: 0 }}
                        >
                          <li>
                            <Link href="/coupon">
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                                คูปอง
                              </Typography>
                            </Link>
                          </li>
                          <li>
                            <Link href="/map">
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                                ค้นหาสถานที่และบริการ
                              </Typography>
                            </Link>
                          </li>
                          <li>
                            <Link href="/community/article">
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                                บทความ
                              </Typography>
                            </Link>
                          </li>
                          <li>
                            <Link href="/community/activity">
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block', // Makes the entire area clickable
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                                กิจกรรม
                              </Typography>
                            </Link>
                          </li>
                          <li>
                            <Link href="/community/video">
                              <Typography
                                sx={{
                                  fontSize: {
                                    xs: '14px', 
                                    sm: '14px', 
                                    md: '14px', 
                                    lg: '14px', 
                                    xl: '14px'
                                  },
                                  display: 'block',
                                  padding: '5px 0px 0px 0px',
                                  lineHeight: '1.5',
                                }}
                              >
                                วิดีโอ
                              </Typography>
                            </Link>
                          </li>
                        </Box>

                      </Item>
                    </Grid>
                    <Grid xs={6} sm={6} md={3} lg={3} sx={{ padding: { xs: 0, md: 2 }, paddingBottom: { md: 0 } }}>
                      <Item className="menu-list">
                        <Box
                          component="h3"
                          id="category-b"
                          sx={{
                            mt: 0,
                            color: "#000000",
                            fontSize: {
                              xs: "16px",  // Font size for extra small screens
                              sm: "16px",  // Font size for small screens
                              md: "18px",  // Font size for medium screens (default desktop sizes)
                              lg: "18px",  // Font size for large screens
                              xl: "18px"   // Font size for extra large screens
                            },
                            lineHeight: "16px" // Set line height
                          }}
                        >
                          ดาวน์โหลดแอปพลิเคชัน

                        </Box>
                        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                          <Grid xs={4} >
                            <Link href="" onClick={handleClickDownload}>
                              <div style={{ maxWidth: "100%", height: "auto" }}>
                                <Image
                                  src={imgLogoMaaboom}
                                  alt="Maaboom Application"
                                  width={120}
                                  height={120}
                                  layout="responsive"
                                  objectFit="contain"
                                  style={{
                                    objectFit: 'contain',
                                  }}
                                  loading="lazy"
                                  placeholder="empty"
                                  blurDataURL={imgLogoMaaboom}
                                />
                              </div>
                            </Link>
                          </Grid>
                          <Grid xs={4} >
                            <Grid container spacing={2} sx={{ alignItems: 'center', p: 0 }}>
                            <Grid xs={12} sx={{ py: 0 }}>
                                <Link href="" onClick={handleClick}>
                                  <Image
                                    src={imgLogoIos}
                                    alt="Maaboom Application"
                                    width={100}
                                    height={50}
                                    layout="responsive"
                                    objectFit="contain"
                                    // priority
                                    style={{ maxWidth: "100%", height: "100%", objectFit: 'contain', }}
                                    loading="lazy"
                                    placeholder="blur"
                                    blurDataURL={imgLogoIos}
                                  ></Image>
                                </Link>
                              </Grid>
                              <Grid xs={12} sx={{ py: 0 }}>
                                <Link href="" onClick={handleClick}>
                                  <Image
                                    src={imgLogoGooglePlay}
                                    alt="PetsPloy Application"
                                    width={100}
                                    height={50}
                                    layout="responsive"
                                    objectFit="contain"
                                    // priority
                                    style={{ maxWidth: "100%", height: "100%", objectFit: 'contain', }}
                                    loading="lazy"
                                    placeholder="blur"
                                    blurDataURL={imgLogoGooglePlay}
                                  ></Image>
                                </Link>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid xs={6} sm={6} md={4} lg={4} sx={{ padding: { xs: 0, md: 2 }, paddingBottom: 0 }}>
                      <Item className="menu-list">
                        <Box
                          component="h3"
                          id="category-b"
                          sx={{
                            mt: 0,
                            color: "#000000",
                            fontSize: {
                              xs: "16px",  // Font size for extra small screens
                              sm: "16px",  // Font size for small screens
                              md: "18px",  // Font size for medium screens (default desktop sizes)
                              lg: "18px",  // Font size for large screens
                              xl: "18px"   // Font size for extra large screens
                            },
                            lineHeight: "16px" // Set line height
                          }}
                        >
                          ติดตามเรา
                        </Box>
                        <Box>
                          <Item className="menu-list" sx={{ p: 0 }}>
                            <Box
                              aria-labelledby="category-d"
                              className="social-group"
                              sx={{
                                ".MuiSvgIcon-root": { fontSize: 24 },
                                ".MuiAvatar-root": { ml: 0, mb: 1 },
                                mb: 1,
                                flexWrap: 'wrap',
                                gap: '5px'
                              }}
                            >
                              <Link
                                href="https://www.facebook.com/maaboom.official"
                                aria-label="Facebook Page: maaboom.official"
                                title="Facebook Page: maaboom.official"
                                target="_blank"
                              >
                                <Avatar sx={{ bgcolor: indigo[500] }}>
                                  <FacebookRoundedIcon />
                                </Avatar>
                              </Link>
                              {/* <Link href="https://twitter.com/pets_ploy">
                                <Avatar sx={{ bgcolor: indigo[500] }}>
                               
                                  <TwitterShareButton
                                  >
                                    <Image src={"/assets/images/icon/xshare.png"} width={50} height={50} />
                                  </TwitterShareButton>
                                </Avatar>
                              </Link> */}
                              <Link 
                                href="https://lin.ee/X0fWkjz" 
                                target="_blank" 
                                aria-label="Line Official Account: ช่องทางการชำระเงิน" 
                                title="Line Official Account: ช่องทางการชำระเงิน"
                              >
                                <Image
                                  src={imageLineOA}
                                  alt={"ช่องทางการชำระเงิน"}
                                  width={40}
                                  height={40}
                                  layout="fixed"
                                  objectFit="cover"
                                  // priority
                                  style={{
                                    objectFit: 'cover',
                                  }}
                                  loading="lazy"
                                  placeholder="empty"
                                  blurDataURL={imageLineOA}
                                />
                              </Link>
                              <Link
                                href="https://page.line.me/910kjjtp"
                                className="ico-line"
                                aria-label="Line Official Account: Maaboom"
                                title="Line Official Account: Maaboom"
                              >
                                <FontAwesomeIcon
                                  icon={faLine}
                                  style={{ width: "20px" }}
                                />
                              </Link>
                              <Link
                                href="https://www.youtube.com/@maaboom-club"
                                className="ico-youtube"
                                target={"_blank"}
                                aria-label="YouTube Channel: Maaboom Club"
                                title="YouTube Channel: Maaboom Club"
                              >
                                <FontAwesomeIcon
                                  icon={faYoutube}
                                  style={{ width: "20px" }}
                                />
                              </Link>
                              <Link
                                href="https://www.instagram.com/maaboom.official/"
                                className="ico-instagram"
                                target={"_blank"}
                                rel="noopener noreferrer"
                                aria-label="Instagram Account: Maaboom Official"
                                title="Instagram Account: Maaboom Official"
                              >
                                <FontAwesomeIcon
                                  icon={faInstagram}
                                  style={{ width: "20px" }}
                                />
                              </Link>
                              <Link
                                href="https://www.tiktok.com/@maaboom"
                                className="ico-tiktok"
                                target={"_blank"}
                                rel="noopener noreferrer"
                                aria-label="TikTok Account: Maaboom"
                                title="TikTok Account: Maaboom"
                              >
                                <FontAwesomeIcon
                                  icon={faTiktok}
                                  style={{ width: "20px" }}
                                />
                              </Link>
                            </Box>
                          </Item>
                        </Box>
                      </Item>
                    </Grid>
                    <Grid xs={6} sm={6} md={4} lg={4} sx={{ padding: { xs: 0, md: 2 }, paddingBottom: 0 }}>
                      <Item className="menu-list">
                        <Box
                          component="h3"
                          id="category-b"
                          sx={{
                            mt: 0,
                            color: "#000000",
                            fontSize: {
                              xs: "16px",  // Font size for extra small screens
                              sm: "16px",  // Font size for small screens
                              md: "18px",  // Font size for medium screens (default desktop sizes)
                              lg: "18px",  // Font size for large screens
                              xl: "18px"   // Font size for extra large screens
                            },
                            lineHeight: "16px" // Set line height
                          }}
                        >
                          การจัดส่ง
                        </Box>
                        <Box component="ul" aria-labelledby="category-d">
                          <li>
                            <Image
                              src={imageLogistic1}
                              alt={"การจัดส่ง"}
                              width={90}
                              height={45}
                              layout="fixed"
                              objectFit="contain"
                              // priority
                              style={{
                                objectFit: 'contain',
                              }}
                              loading="lazy"
                              placeholder="empty"
                              blurDataURL={imageLogistic1}
                            />
                            <Image
                              src={imageLogistic2}
                              alt={"การจัดส่ง"}
                              width={90}
                              height={45}
                              layout="fixed"
                              objectFit="contain"
                              // priority
                              style={{
                                objectFit: 'contain',
                              }}
                              loading="lazy"
                              placeholder="empty"
                              blurDataURL={imageLogistic2}
                            />
                            <Image
                              src={imageLogistic3}
                              alt={"การจัดส่ง"}
                              width={90}
                              height={45}
                              layout="fixed"
                              objectFit="contain"
                              // priority
                              style={{
                                objectFit: 'contain',
                              }}
                              loading="lazy"
                              placeholder="empty"
                              blurDataURL={imageLogistic3}
                            />
                          </li>
                          {/* <li>
                            <Image 
                            src={imageLogistic4} 
                            alt={"การจัดส่ง"} 
                            width={120} 
                            height={45}
                            layout='fixed'
                            objectFit="contain"
                            // priority
                            loading="lazy"
                            placeholder="empty"
                            blurDataURL={imageLogistic4}
                            />
                          </li> */}
                        </Box>
                      </Item>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} lg={4} sx={{ padding: { xs: 0, md: 2 }, paddingBottom: 0 }}>
                      <Item className="menu-list">
                        <Box
                          component="h3"
                          id="category-b"
                          sx={{
                            mt: 0,
                            color: "#000000",
                            fontSize: {
                              xs: "16px",  // Font size for extra small screens
                              sm: "16px",  // Font size for small screens
                              md: "18px",  // Font size for medium screens (default desktop sizes)
                              lg: "18px",  // Font size for large screens
                              xl: "18px"   // Font size for extra large screens
                            },
                            lineHeight: "16px" // Set line height
                          }}
                        >
                          ช่องทางการชำระเงิน
                        </Box>
                        <Box component="ul" aria-labelledby="category-c">
                          <li>
                            <Image
                              src={imagePayment}
                              alt={"ช่องทางการชำระเงิน"}
                              width={200}
                              height={42}
                              layout="fixed"
                              objectFit="contain"
                              // priority
                              style={{
                                objectFit: 'contain',
                              }}
                              loading="lazy"
                              placeholder="empty"
                              blurDataURL={imagePayment}
                            />
                          </li>
                          {/* <li>
                          <Image 
                            src={imagePayment2} 
                            alt={"ช่องทางการชำระเงิน"} 
                            width={120} 
                            height={45}
                            layout='fixed'
                            objectFit="contain"
                            // priority
                            loading="lazy"
                            placeholder="empty"
                            blurDataURL={imagePayment2}
                            />
                        </li> */}
                          {/* <li>
                          <Image 
                            src={imagePayment3} 
                            alt={"ช่องทางการชำระเงิน"} 
                            width={120} 
                            height={45}
                            layout='fixed'
                            objectFit="contain"
                            // priority
                            loading="lazy"
                            placeholder="empty"
                            blurDataURL={imagePayment3}
                            />
                        </li> */}
                        </Box>
                      </Item>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <Box
              className="footer-bottom"
            >
              <Container maxWidth="lg" sx={{ py: 0 }}>
                <Grid
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection={{ xs: "column", sm: "row" }}
                  sx={{ fontSize: "12px" }}
                >
                  <Grid
                    sx={{
                      order: { xs: 2, sm: 1 },
                      color: "#F0F0F0",
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: {
                          xs: 'column', // Stack items vertically on smaller screens
                          md: 'row',    // Align items horizontally on larger screens
                        },
                        alignItems: 'center', // Center items vertically on larger screens
                        justifyContent: 'center', // Center items horizontally
                        gap: {
                          xs: '8px', // Add space between items on smaller screens
                          md: '4px', // Slightly reduce space on larger screens
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#F0F0F0",
                          fontSize: "12px",
                        }}
                      >
                        © {new Date().getFullYear()} maaboom Shopping,
                      </Typography>

                      <Link target="_blank" href="https://www.dgmad.com/">
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontSize: "12px",
                          }}
                        >
                          &nbsp;By Digital Media Advertising Co., Ltd.
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>

                  <Grid
                    container
                    columnSpacing={1}
                    sx={{
                      order: { xs: 1, sm: 2 },
                      color: "#F0F0F0",
                    }}
                  >
                    {[
                      { href: "/policy/terms", label: "เงื่อนไขในการให้บริการ" },
                      { href: "/policy/privacy-policy", label: "นโยบายความปลอดภัย" },
                      { href: "/policy/cookie", label: "Cookie Policy" },
                    ].map((item, index) => (
                      // <Grid item xs="auto" key={index}>
                      <Grid
                        xs="auto"
                        key={index}
                      >
                        <Link href={item.href} target="_blank">
                          <Typography
                            sx={{
                              color: "#F0F0F0",
                              fontSize: "12px",
                              marginRight: index !== 2 ? "20px" : 0, // Add margin except for the last item
                            }}
                          >
                            {item.label}
                          </Typography>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </footer>
      )}
    </>
  );
}
